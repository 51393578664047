import React from 'react'
import { HTMLAttributes, ReactNode } from 'react'

interface SubtitleProps extends HTMLAttributes<HTMLHeadingElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children?: ReactNode
}

export const Subtitle = ({
  as: Element = 'h4',
  children,
  ...props
}: SubtitleProps) => {
  return (
    <Element
      tw="text-xl sm:text-2xl lg:text-2xl xl:text-3xl font-semibold"
      {...props}
    >
      {children}
    </Element>
  )
}
