import React from 'react'
import { Helmet } from 'react-helmet'
import { Footer } from '../components/Layout/Footer'
import { Container } from '../components/UI/Container'
import { PrivacyPolicyContent } from '../views/privacy-policy/PrivacyPolicyContent'
import { Navbar } from '../components/Layout/Navbar'

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy — Commercium Bank, Inc</title>
      </Helmet>

      <Navbar />

      <Container tw="pt-32 pb-20">
        <PrivacyPolicyContent />
      </Container>

      <Footer />
    </div>
  )
}

export default PrivacyPolicyPage
